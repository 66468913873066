// themes - our custom or/and out of the box themes
@import 'app/@theme/styles/styles';
//@import '~@angular/material/theming';
@import '~@ng-select/ng-select/scss/material.theme';
@import '~font-awesome/scss/font-awesome';

@import 'scss/variables';

[class^="#{$fa-css-prefix}-"], [class*=" #{$fa-css-prefix}-"] {
  @extend .#{$fa-css-prefix};
}

html, body {
  font-family: Poppins, Arial, sans-serif;
  font-size: 14px;
}

// *:not(i):not(mat-icon):not(nb-icon):not(.dx-header-filter):not(.menu-icon):not(.fa):not(.dx-navigate-button):not(.dx-checkbox-icon) {
*:not(i):not(mat-icon):not(nb-icon):not(.dx-header-filter):not(.menu-icon):not(.fa):not([class^=fa-]):not([class*=" fa-"]):not(.dx-navigate-button):not(.dx-checkbox-icon):not(.dx-datagrid-group-closed):not(.dx-datagrid-group-opened):not(.dx-icon-search):not(.dx-icon-clear):not(.ace_content):not(.dx-dropdowneditor-icon) {
  font-family: Poppins, Arial, sans-serif;
}

.ace_editor, .ace_editor * {
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace !important;
  font: 12px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
  font-variant-ligatures: no-common-ligatures !important;
}

#task-description * {
  font-size: 13px !important;
  display: inline !important;
  font-weight: 400 !important;
  margin: 0 !important;
  text-decoration: none !important;
  cursor: default !important;
  pointer-events: none !important;
  font-size: none !important;
  line-height: inherit !important;
}

.dx-treelist-rowsview .dx-treelist-expanded span, .dx-treelist-rowsview .dx-treelist-expanded span::before,
.dx-treelist-rowsview .dx-treelist-collapsed span, .dx-treelist-rowsview .dx-treelist-collapsed span::before {
    font-family: 'DXIcons' !important;
}

ngx-help-icon {
    display: none;
}
 
.jumbotron, .jumbotron-fluid {
  background-color: #f9f9f9;
  padding: 13px;
}

$custom-typography: mat-typography-config(
  $font-family: 'Poppins',
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(14px, 24px, 500)
);

@include mat-core($custom-typography);

$candy-app-primary: mat-palette($mat-green);
$candy-app-accent: mat-palette($mat-grey);
$candy-app-warn: mat-palette($mat-red);
$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
$ng-select-highlight: $mat-green;
@include angular-material-theme($candy-app-theme);

@import 'scss/material/forms';
@import 'scss/material/buttons';
@import 'scss/material/tabs';
@import 'scss/modals';

@import 'scss/buttons';
@import 'scss/text';
@import 'scss/forms';
@import 'scss/ng-select';
@import 'scss/smartour';

.nb-theme-default a {
  color: #292929;
}

.nb-theme-default nb-layout .layout {
  color: #292929;
}

.swal2-container {
  z-index: 99999 !important;
}

.modal-fit-content .modal-dialog {
  max-width: fit-content !important;
  width: fit-content !important;
  .modal-content {
    width: fit-content !important;
    voucher-generation-modal,
    financial-report {
      width: min-content !important;
      min-width: 1250px !important;
    }
  }
}
