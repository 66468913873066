// @import './forms';
//@import './search';
@import './menu.scss';
@import '~@angular/material/theming';

@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$candy-app-primary: mat-palette($mat-blue);
$candy-app-accent: mat-palette($mat-indigo, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);

.mat-form-field {
  width: 100%;
}

$everest_primary_color: #41b146;

body,
input,
select,
textarea,
body * {
  // font-family: 'Roboto', sans-serif;
  box-sizing: border-box;

  &::after,
  &::before {
    box-sizing: border-box;
  }
}

input {
  color: black !important;
}

.modal {
  // z-index: 9999 !important;
}

.cdk-overlay-container {
  z-index: 99999 !important;
}

// white background for all the app
.nb-theme-default nb-layout.window-mode {
  // background: white !important;
}

.nb-theme-default nb-layout .layout {
  // background: white !important;
}

.nb-theme-default nb-layout .layout .layout-container .content .columns nb-layout-column {
  padding: 0 !important;
  height: 75vh;
}

.nb-theme-default .modal-content {
  left: 11px;
}

h1 {
  font-size: 2rem;
  text-align: center;
  margin: 0 0 2em;

  small {
    font-size: 50%;
    color: #666;
    display: block;
  }
}

h6 {
  padding: 15px;
  //font-family: inherit !important;
  text-decoration: none !important;
  color: #989898;
}

// .nb-theme-default a {
//     color: #36A4EB !important;
// }

.everest-content {
  margin-top: -35px;
}

// .container {
// 	position: relative;
// 	max-width: 40rem;
// 	// margin: 4rem auto;
// 	// background: $body-bg;
// 	width: 100%;
// 	padding: 3rem 5rem 0;
// 	border-radius: 1px;

// 	&::before {
// 		content: '';
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		right: 0;
// 		bottom: 0;
// 		// box-shadow: $shadow-8;
// 		transform: scale(0.98);
// 		// transition: transform $mf-transition-speed ease-in-out;
// 		z-index: -1;
// 	}

// 	&:hover::before {
// 		transform: scale(1);
// 	}
// }

// return icon for side detail blocks
.back-arrow {
  font-size: 1.3rem;
  color: #777777;
}

.button-container {
  text-align: center;
}

.btn-xs {
  cursor: pointer !important;
  padding: 5px !important;
}

.border-bottom-only {
  padding: 15px;
  border-right: white 1px solid !important;
  border-left: white 1px solid !important;
  border-top: white 1px solid !important;
  border-bottom: #ddd 1px solid !important;
}

.air {
  min-height: 100px;
}

.title-case {
  text-transform: capitalize;
}

i.required {
  color: #c74141;
}

// change grid grouping color header
.dx-datagrid-rowsview .dx-row.dx-group-row {
  background-color: white !important;
}

.dx-datagrid-rowsview .dx-row {
  border-top: inherit !important;
  border-bottom: inherit !important;
}

// selectbox height
.nb-theme-default select.form-control:not([size]):not([multiple]) {
  height: calc(1.5rem + 20px) !important;
}

// change hover color of the devexpress grid
tr.dx-state-hover td {
  background-color: rgba(54, 163, 235, 0.3) !important;
  color: #333 !important;

  .dx-datagrid-group-closed,
  .dx-datagrid-group-opened {
    color: white !important;
  }
}

// remove gray background around subtable (work progress grid)
.dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) > .dx-datagrid-group-space,
.dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) .dx-master-detail-cell {
  background: white !important;
}

// remove border around the detail subtable
.dx-datagrid-rowsview .dx-master-detail-row > .dx-datagrid-group-space,
.dx-datagrid-rowsview .dx-master-detail-row .dx-master-detail-cell {
  border-top: 0 solid #fff !important;
  border-bottom: 0 solid #fff !important;
}

// collapsable inner table padding
.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
  padding: 4px !important;
}

td.dx-command-expand .dx-datagrid-group-space {
  background: black !important;
}

// risks get the correct height  in position of the item
.dx-datagrid-content .dx-datagrid-table .dx-row > td {
  vertical-align: middle !important;
}

// row selected by the user. See EV-241
.dx-datagrid-rowsview .dx-selection.dx-row.dx-column-lines > td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover.dx-column-lines > td {
  background-color: #36a4eb !important;
  color: white !important;

  svg {
    .icon-element {
      .icon {
        fill: #ffffff !important;
      }
    }
  }
}

.dx-datagrid-header-panel {
  border: none !important;
}

.dx-datagrid-header-panel .dx-toolbar {
  width: 100% !important;
  padding-top: 22px !important;
}

.close-details {
  font-size: 20px;
  padding: 30px;
}

// plus circle buttons
.icon-add {
  color: #1b6dbf;
}

.margin10 {
  margin: 10px;
}

// .nb-theme-default .btn {
// 	padding: .5rem 2rem ;
// }

.btn-primary {
  background-color: $everest_primary_color !important;
  cursor: pointer !important;
}

.btn-group-toggle {

  .btn-primary {
    background-color: rgba($everest_primary_color, 0.8) !important;
    border-color: rgba($everest_primary_color, 0.8) !important;

    &:focus {
      box-shadow: rgba($everest_primary_color, 0.5) !important;
    }

    outline: none !important;

    &:first-child {
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }

    &:last-child {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }

  }

  .btn-primary.active {
    background-color: $everest_primary_color !important;
  }
}

.btn-secondary {
  background-color: #868e96 !important;
  color: white !important;
  border: none !important;

  &:hover {
    background-color: rgba(#868e96, 0.8) !important;
  }
}

.btn-default {
  :hover {
    color: #555 !important;
  }

  background-color: #f3f3f3 !important;
  color: #333 !important;
}

.nb-theme-default:hover {
  color: #333 !important;
}

// remove green color around avatar dropdown
.nb-theme-default nb-user .context-menu,
.nb-theme-default nb-user .context-menu ul > li.arrow {
  border: 2px solid #d3d3d3 !important;
}

nb-context-menu .menu-item a {
  color: #292929 !important;
}

.nb-theme-default nb-user {

  .user-name {
    font-size: 0.9rem;
    font-weight: bold;
    color: #292929;
  }

  .user-name::before {
    content: 'Hi, ';
    font-weight: 400;
  }

  .user-picture {
    border: none !important;
  }

  .user-title {
    font-size: 0.7rem;
    color: #333333;
  }

}

// loading screen
.nb-theme-default nb-layout .layout {
  font-size: 14px;
}

.nb-theme-default nb-menu .menu-item {
  .menu-icon {
    font-size: 1.3rem !important;
    color: #f0f0f0 !important;
    // text-align: right !important;
    margin: 0 1rem 0 0;

    /*
      &.fa {
        font-size: 1.5rem !important;
      }*/

  }

  .menu-icon.ion-dashboard-icon {
    // font-size: 1.1rem !important;
    // margin: 0 0.5rem 0 0.3rem;
  }
}

.top-searchbox {
  outline: none !important;
  border: #fff;
}

.nb-theme-default nb-layout.window-mode {
  padding-top: 0 !important;

  .scrollable-container {
    height: 100% !important;
  }
}

.scrollable-container {
  overflow-x: hidden !important;
  max-width: 100% !important;
  overflow-y: auto !important;
}

.toast-close-button {
  top: 0.3em !important;
  right: 0.3em !important;
  position: absolute !important;
}

.iti__selected-flag {
  padding: 0;
}

.iti--allow-dropdown input,
.iti--allow-dropdown input[type=text],
.iti--allow-dropdown input[type=tel],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type=text],
.iti--separate-dial-code input[type=tel] {
  padding-left: 45px;
}
